import React, { useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18next'
import '../../i18n'

import Layout from '../components/Layout/Layout'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      logo {
        asset {
          url
          _id
        }
      }
    }

    companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
      facebookUrl
      twitterUrl
      linkedinUrl
      instagramUrl
    }

    awards: sanityAwards(_id: { regex: "/(drafts.|)awards/" }) {
      trustpilotReview {
        url
        image {
          asset {
            url
            _id
          }
        }
      }

      allAwards {
        title
        url
        image {
          asset {
            url
            _id
          }
        }
      }
    }
  }
`

const LayoutContainer = (props) => {
  const [showNav, setShowNav] = useState(false)
  function handleShowNav() {
    setShowNav(true)
  }
  function handleHideNav() {
    setShowNav(false)
  }

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
          )
        }

        const social = data.companyInfo
          ? {
              facebook: data.companyInfo.facebookUrl || null,
              twitter: data.companyInfo.twitterUrl || null,
              linkedin: data.companyInfo.linkedinUrl || null,
              instagram: data.companyInfo.instagramUrl || null
            }
          : {}

        const { site, awards } = data

        return (
          <>
            <I18nextProvider i18n={i18n}>
              <Layout
                {...props}
                showNav={showNav}
                onHideNav={handleHideNav}
                onShowNav={handleShowNav}
                siteTitle={site && site.title}
                social={social}
                logo={site && site.logo}
                awards={awards || {}}
              />
            </I18nextProvider>
          </>
        )
      }}
    />
  )
}

export default LayoutContainer
